import React from "react";

const Logo = class extends React.Component {
  render() {
    return (
      <svg
  version="1.1"
  id="Layer_1"
  x="0px"
  y="0px"
  viewBox="0 0 671.7 141"
>
<defs>
    <radialGradient id="myGradient" cx="25%" cy="50%">
      <stop offset="10%" stop-color="rgba(255, 255, 255, .25)" />
      <stop offset="90%" stop-color="rgba(255, 255, 255, 1)" />
    </radialGradient>
  </defs>
  <g>
    <path
      class="logo-white"
      d="M79.1,110.3C79.1,131,61,141,40.4,141C21.2,141,6.9,133,0,118.2L21.6,106c2.7,7.9,9.2,12.5,18.9,12.5
		c7.9,0,13.3-2.7,13.3-8.3C53.7,96.2,4,103.9,4,70c0-19.6,16.7-30.6,36.6-30.6c15.6,0,29.1,7.1,36.6,20.4L56,71.4
		c-2.9-6.2-8.3-9.8-15.4-9.8c-6.2,0-11.2,2.7-11.2,7.9C29.4,83.7,79.1,74.9,79.1,110.3z"
    />
    <path
      class="logo-white"
      d="M226.9,42.1l-30.6,96.2h-23.7l-17.7-58.3l-17.7,58.3h-23.7L82.9,42.1h26.4l16.4,59.1l17.5-59.1h23.5l17.5,59.3
		l16.4-59.3H226.9z"
    />
    <path
      class="logo-white"
      d="M237.4,15.4c0-8.3,6.9-15.4,15.2-15.4c8.5,0,15.4,7.1,15.4,15.4s-6.9,15.2-15.4,15.2
		C244.4,30.6,237.4,23.7,237.4,15.4z M240.3,42.1h24.8v96.2h-24.8V42.1z"
    />
    <path
      class="logo-white"
      d="M319.2,41.2v1h20.4V66h-20.4v72.4h-24.8V66h-13.9V42.1h13.9v-1C294.4,14.6,309-1,339.6,1v23.9
		C326.7,23.9,319.2,28.1,319.2,41.2z"
    />
    <path
      class="logo-white"
      d="M387.3,66v40c0,10.4,7.5,10.6,21.7,9.8v22.5c-34.8,3.8-46.6-6.4-46.6-32.3V66h-16.7V42.1h16.7V22.7l24.8-7.5
		v26.9h21.7V66H387.3z"
    />
  </g>
  <polygon
    class="logo-grad"
    points="643,42.1 607.8,91.2 641.7,138.4 670.2,138.4 636.5,91.4 671.7,42.1 "
  />
  <g>
    <path
      class="logo-orange"
      d="M453.3,41.2v1h20.4V66h-20.4v72.4h-24.8V66h-13.9V42.1h13.9v-1c0-26.6,14.6-42.1,45.2-40.2v23.9
		C460.8,23.9,453.3,28.1,453.3,41.2z"
    />
    <path
      class="logo-orange"
      d="M476.4,90.2c0-28.7,22.5-50.8,50.8-50.8c28.3,0,51,22.1,51,50.8s-22.7,50.8-51,50.8
		C498.9,141,476.4,118.9,476.4,90.2z M553.4,90.2c0-15.6-11.4-26.6-26.2-26.6c-14.6,0-26,11-26,26.6c0,15.6,11.4,26.6,26,26.6
		C542,116.8,553.4,105.8,553.4,90.2z"
    />
    <polygon
      class="logo-orange"
      points="600.9,42.1 636.1,91.2 602.2,138.4 573.8,138.4 607.4,91.4 572.2,42.1 	"
    />
  </g>
</svg>


    );
  }
};

export default Logo;
