import React from "react";
import { Link } from "gatsby";
import Logo from "./Logo";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  render() {
    return (
      <div
        data-scroll
        data-scroll-sticky
        data-scroll-target="#container"
        className="nav-container"
      >
        <div className="mobilemenu">
          <div className="mobilemenu-container">
            <Link
              to="/"
              className="logo-mobile desktop-only"
              aria-label="homepage"
            >
              <Logo />
            </Link>
            <Link
              to="/"
              className="logo-mobile mobile-only"
              aria-label="homepage"
            >
              <Logo />
            </Link>
            <div className="mobile-only-menu">
              <input className="burger" type="checkbox" />
              <nav>
                <Link
                  to="/"
                  activeStyle={{ color: "#FF6400" }}
                  className="item"
                >
                  Home
                </Link>
                <Link to="/business" activeStyle={{ color: "#FF6400" }} className="item">
                  Business
                </Link>
                <Link to="/personal" activeStyle={{ color: "#FF6400" }} className="item">
                  Personal
                </Link>
                <Link to="/live-fx" activeStyle={{ color: "#FF6400" }} className="item">
                  Live FX
                </Link>
                <Link to="/faq" activeStyle={{ color: "#FF6400" }} className="item">
                FAQs
                </Link>
                <Link to="/contact-us" activeStyle={{ color: "#FE6300" }} className="item">
                Contact
                </Link>
                <a href="https://swiftfox.paydirect.io/login" target="_blank" className="item" rel="noreferrer">
                  Log In
                </a>
                <Link to="/open-an-account" className="item">
                  Open an account
                </Link>
              </nav>
            </div>
            <div className="dektop-only-menu">
              <div className="navigation">
                <Link to="/" activeStyle={{ color: "#FF6400" }} className="item">
                  Home
                </Link>
                <Link to="/business" activeStyle={{ color: "#FE6300" }} className="item">
                  Business
                </Link>
                <Link to="/personal" activeStyle={{ color: "#FE6300" }} className="item">
                  Personal
                </Link>
                <Link to="/live-fx" activeStyle={{ color: "#FF6400" }} className="item">
                  Live FX
                </Link>
                <Link to="/faq" activeStyle={{ color: "#FE6300" }} className="item">
                FAQs
                </Link>
                <Link to="/contact-us" activeStyle={{ color: "#FE6300" }} className="item">
                Contact
                </Link>
                <a href="https://swiftfox.paydirect.io/login" target="_blank" className="main-button" rel="noreferrer">
                  Log In
                </a>
                <Link to="/open-an-account" className="main-button-alt">
                  Open an account
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Navbar;
